import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";

const query = graphql`
  {
    allDocx {
      edges {
        node {
          content
        }
      }
    }
  }
`;

const PrivacyPolicy = () => {
  const data = useStaticQuery(query);
  return (
    <section>
      <Layout>
        <Navbar />
        <div className="page privacy">
          <div className="container">
            {/* <div>{parse(data.allDocx.edges[0].node.content)}</div> */}
            <h1 className="title">PRIVACY POLICY</h1>
            <p>
              This Privacy Policy (Policy) covers how Sea Easy Capital Pte.
              Ltd., our related corporations and affiliates (collectively SEC)
              handle personal data collected on https://seaeasycapital.com/, its
              sub-domains as well as through other electronic or non-electronic
              means (collectively, the Platform), which is subject to the
              Personal Data Protection Act (No. 26 of 2012 (the PDPA). This
              Policy does not derogate from any of SEC’s Terms and Conditions
              and Terms of Use (collectively, the Terms). Also, this Policy
              supplements but does not supersede nor replace any other consents
              you may have provided or will provide to SEC. Your consents herein
              are additional to any rights which SEC may have at law to collect,
              use or disclose your Personal Data and nothing herein is to be
              construed as limiting any of these other rights.{" "}
            </p>
            <ol>
              <li>
                <h1>Definitions and Application</h1>
                <p>In this Policy: </p>
                <ol className="sub-list">
                  <li>
                    Personal Data refers to any data which enables an individual
                    to be identified from that data, or from that data and other
                    information to which the organisation has or is likely to
                    have access, including but not limited to:
                    <ol className="lower-alpha">
                      <li>
                        information including name, date of birth, gender,
                        nationality, contact details, IC/passport number,
                        address and/or other identifying details of individuals;
                        and{" "}
                      </li>
                      <li>
                        interactions with SEC including recordings of phone
                        calls, emails and other records of any contact with SEC;
                        and
                      </li>
                    </ol>
                  </li>
                  <li>
                    Services refers to any of the services provided by SEC.
                  </li>
                  <li>
                    This Policy applies to all:
                    <ol className="lower-alpha">
                      <li>visitors to the Platform;</li>
                      <li>
                        persons who register their emails with the SEC; and
                      </li>
                      <li>
                        entities, corporations, firms and partnerships which
                        register on our Platform; and{" "}
                      </li>
                      <li>
                        companies, suppliers and purchasers involved in the
                        factoring of invoices through the Platform (the
                        Clients),
                      </li>
                      <p>(collectively, the Users or You).</p>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Information provided to SEC</h1>
                <ol className="sub-list">
                  <li>
                    By accessing, using or continuing to access or use the
                    Platform and/or our Services, you, as a User confirm,
                    represent and warrant to SEC that:-
                    <ol className="lower-alpha">
                      <li>you have read and understood this Policy;</li>
                      <li>
                        you agree to be legally bound by the terms of this
                        Policy; and
                      </li>
                      <li>
                        you consent to the collection, processing, use and
                        disclosure of Personal Data provided by you as set out
                        in this Policy.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Any consent given in relation to Personal Data shall,
                    subject to all applicable laws and regulations, survive
                    death, incapacity, bankruptcy or insolvency of any such
                    individual and the termination or expiration of any account
                    in connection with the use of the Platform.
                  </li>
                  <li>
                    If you are a corporation and are providing or disclosing the
                    Personal Data of any Relevant Individual (as defined below)
                    to us, you hereby represent and warrant to us that you have
                    obtained such individual’s consent to provide their Personal
                    Data to us in accordance with this Policy.
                  </li>
                  <li>
                    You shall ensure at all times that any information provided
                    to us (including Personal Data) is correct, accurate and
                    complete.
                  </li>
                </ol>
              </li>
              <li>
                <h1>How we collect Personal Data</h1>
                <ol className="sub-list">
                  <li>
                    We may collect your data (including Personal Data) when you
                    visit the Platform or communicate with us via emails or
                    other mediums. Such collection may be by way of monitoring
                    your access and use of the Platform through the use of
                    cookies and similar tracking devices. Cookies are small data
                    files stored in your electronic devices that recognises and
                    identifies your device which allows us to remember you or
                    other data about you for future use. This information will
                    help us to build a profile of our Users. Some of this data
                    may be aggregated or statistical, meaning that we may not be
                    able to personally identify an individual solely from such
                    data. You may delete the cookies from your web browser
                    and/or turn adjust your web browser’s settings to prevent
                    the processing of cookies, but this may affect your user
                    experience on the Platform.
                  </li>
                  <li>
                    Information and data may also be collected in the ordinary
                    course of your relationship with us. For example, through
                    the use of your account on the Platform, to use any of our
                    Services, when you submit documents in relation to the
                    Services.
                  </li>
                  <li>
                    We may collect Personal Data from users, third parties,
                    including the user’s beneficial owners, partners, directors,
                    officers or authorised signatories, employees, customers,
                    payors, payees, guarantors, other security providers and
                    other natural persons related to that user (the Relevant
                    Individuals); and/or publicly available sources including
                    credit bureaus.
                  </li>
                  <li>
                    SEC may also collect information from or about the devices
                    which are used to access the Platform. Such information
                    includes, but is not limited to:
                    <ol className="lower-alpha">
                      <li>
                        attributes such as the operating system of the device,
                        hardware version, settings, file and software names and
                        types and device identifiers;{" "}
                      </li>
                      <li>device locations; and</li>
                      <li>
                        connection information such as the name of the ISP or
                        mobile operator through the Platform is accessed,
                        browser type/language and IP address.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Our Platform may link to third parties and affiliates. This
                    Privacy Policy does not apply to how third parties and
                    affiliates define Personal Data or how they use it. You
                    agree that we will not be responsible or liable for Personal
                    Data that may be collected by third-parties and affiliates.
                  </li>
                </ol>
              </li>
              <li>
                <h1>Usage of Personal Data</h1>
                <ol className="sub-list">
                  <li>
                    SEC may collect, store, process, disclose, access, review
                    and/or use Personal Data in accordance with the PDPA or any
                    other written law.
                  </li>
                  <li>
                    Without prejudice to the foregoing, SEC may use Personal
                    Data for any of its business purposes, which include, and is
                    not limited to, the following purposes:
                    <ol className="lower-alpha">
                      <li>
                        for the specific purpose for which the Personal Data was
                        volunteered;
                      </li>
                      <li>
                        to assess and process your applications for our
                        Services;{" "}
                      </li>
                      <li>
                        to verify your identity, process payments and manage our
                        administrative and business operations;
                      </li>
                      <li>
                        to manage and maintain your account with us and to
                        provide Services to you on the Platform,{" "}
                      </li>
                      <li>
                        to maintain your credit history and generate credit
                        assessment reports;
                      </li>
                      <li>
                        {" "}
                        to utilise the same for strategic alliances, cross
                        selling, marketing and promotions, surveys and to
                        understand your needs and preferences, and to provide
                        you with information on our and third-party products and
                        services which may be of interest to you;
                      </li>
                      <li>
                        to improve our website, Services and communications to
                        you, and to improve your browsing and user experience;
                      </li>
                      <li>
                        in connection with any potential or actual sale, merger,
                        or similar change of SEC’s business;
                      </li>
                      <li>
                        to comply with any applicable laws, rules, or
                        regulations of any country, or to assist in law
                        enforcement and investigations conducted by any
                        governmental and/or regulatory authority;
                      </li>
                      <li>
                        to collect any debt due or owing to us and to enforce
                        your obligations to us;{" "}
                      </li>
                      <li>
                        to detect and prevent fraud, crime, offences,
                        money-laundering, counter terrorist financing, bribery
                        and/or other illegal activities and to disclose the same
                        for investigation, prevention, prosecution and
                        compliance with sanctions;
                      </li>
                      <li>
                        to communicate with you including responding to your
                        enquiries and/or complaints and to enforce SEC’s rights,
                        procuring legal advice and dispute resolution;
                      </li>
                      <li>
                        to utilise the same for our outsourcing of business
                        operations; and
                      </li>
                      <li>any other purposes relating to any of the above.</li>
                    </ol>
                  </li>
                  <li>
                    You consent to receiving communication (through any medium
                    whatsoever) from SEC, which may include marketing materials
                    such as newsletters, updates and promotions about the
                    Platform and may opt out of receiving such communication
                    through the Platform.
                  </li>
                </ol>
              </li>
              <li>
                <h1>Parties to whom Personal Data may be Disclosed</h1>
                <ol className="sub-list">
                  <li>
                    SEC may from time to time also disclose Personal Data to the
                    following parties (whether inside or outside of Singapore)
                    for the purposes listed above:
                    <ol className="lower-alpha">
                      <li>
                        SEC’s directors, officers, employees, agents, holding
                        companies, subsidiaries and affiliates;{" "}
                      </li>
                      <li>
                        purchasers involved in the factoring of invoices through
                        the Platform;{" "}
                      </li>
                      <li>
                        contractors, service providers, professional advisers of
                        SEC;
                      </li>
                      <li>
                        any actual or proposed assignee of SEC or transferee of
                        SEC’s rights in respect of all or any part of the assets
                        or business of SEC;{" "}
                      </li>
                      <li>
                        any party for purposes of meeting or complying with
                        SEC’s internal policies and procedures and any
                        applicable rules, laws, regulations, codes of practice
                        or guidelines, orders or requests issued by any court,
                        legal or regulatory bodies (both national and
                        international) (including but not limited to disclosures
                        to regulatory bodies, conducting audit checks or any
                        investigations);
                      </li>
                      <li>
                        any credit bureau (including without limitation Credit
                        Bureau (Singapore) Pte Ltd) as well as the members of
                        such credit bureau and other relevant third parties in
                        connection with the use of the Platform (e.g. banks,
                        financial intermediaries, insurers, reinsurers, escrow
                        agents and other P2P platforms);
                      </li>
                      <li>
                        strategic or business partners with whom SEC have a
                        relationship with for specific collaboration, products
                        and/or services; and{" "}
                      </li>
                      <li>
                        any person connected to the enforcement agencies or as
                        preservation of any of our rights under your agreements
                        with us.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Without prejudice to the foregoing, SEC may disclose
                    Personal Data to any person or entity to whom SEC is under
                    an obligation or otherwise required to make disclosure
                    pursuant to any applicable laws and regulations, including
                    disclosure to courts, tribunals, and/or legal, regulatory,
                    tax and government authorities in Singapore or otherwise.
                  </li>
                  <li>
                    If any business contact information (as defined under the
                    PDPA) is provided to us, we are not required to obtain your
                    consent before collecting, using or disclosing any such
                    business contact information to third parties.{" "}
                  </li>
                </ol>
              </li>
              <li>
                <h1>Storage and security of Personal Data</h1>
                <ol className="sub-list">
                  <li>SEC will use all reasonable endeavours to secure Personal 
                    Data provided to us on the Platform and prevent it from 
                    abuse, misuse, interference, loss and against unauthorized 
                    access, alteration or disclosure. SEC has put in place 
                    appropriate technical, organisational and other security 
                    measures to protect the Personal Data provided to us.
                  </li>
                  <li>SEC will equip its servers with Secure Socket Layer (SSL) 
                    certificate technology to ensure that all data and 
                    transactions you enter on the Platform is encrypted. 
                    Verification of SSL is provided with the key or padlock icon 
                    on your browser.
                  </li>
                  <li>You acknowledge and consent that Personal Data submitted by 
                    you shall be collected, stored and processed by us and/or our 
                    partner(s) and may be transferred to other countries outside 
                    of Singapore depending on the location of the server(s).
                  </li>
                  <li>Personal Data which is provided by you or submitted by you 
                    which is published on the Platform with your consent may be 
                    available to the public in other parts of the world through 
                    the Internet. 
                    As such, you acknowledge that we cannot prevent the use 
                    and/or misuse of Personal Data by such parties.
                  </li>
                </ol>
              </li>
              <li>
                <h1>Retention of Personal Data</h1>
                <ol className="sub-list">
                  <li>SEC may retain and preserve the Personal Data for so long 
                    as it may deem necessary for the purposes of record 
                    retention and for its own business purposes in connection 
                    with the operation of the Platform, or as required or 
                    permitted by applicable laws.
                  </li>
                  <li>If you are a Client, please note that the Personal Data 
                    may continue to be used for the purposes permitted under 
                    this Policy even in situations where your relationship 
                    with us has been terminated or altered in any way, for a 
                    reasonable period thereafter (including, where applicable, 
                    a period to enable us to enforce this Policy, the Terms, 
                    and/or any agreements which you have entered into with us).
                  </li>
                </ol>
              </li>
              <li>
                <h1>Withdrawal of Consent</h1>
                <ol className="sub-list">
                  <li>Your consent provided for the collection, use and 
                    disclosure of Personal Data will remain valid until such 
                    time it has been withdrawn by you in writing. You may 
                    withdraw consent and request us to stop using and/or 
                    disclosing the Personal Data provided by you for any or all 
                    of the purposes permitted under this Policy by submitting 
                    your request to our Data Protection Officer at [●].
                  </li>
                  <li>In the event any individual whose Personal Data you have 
                    provided to us withdraws his or her consent to the 
                    collection, processing, use and/or disclosure of his or her 
                    Personal Data by SEC, you shall notify us immediately upon 
                    becoming aware of such fact.
                  </li>
                  <li>SEC will require reasonable time to process such requests, 
                    and you acknowledge that such withdrawal of consent may 
                    affect the Services which SEC is able to provide to you.
                  </li>
                  <li>Please note that withdrawing consent does not affect our 
                    right to continue to collect, use and disclose personal data 
                    where such collection, use and disclosure without consent is 
                    permitted or required under applicable laws.
                  </li>
                </ol>
              </li>
              <li>
                <h1>Notification of Breach</h1>
                <ul>
                  <li>In the event of any data breach that results in, or is 
                    likely to result in significant harm to an affected 
                    individual or User, SEC shall as soon as practicable notify 
                    the User and/or the relevant individuals of such breach.
                  </li>
                </ul>
              </li>
              <li>
                <h1>Access and Correction</h1>
                <ol className="sub-list">
                  <li>You may access, make corrections to, or update the Personal 
                    Data provided by you and held by SEC through the Platform.
                  </li>
                  <li>If you wish to access the Personal Data relating to you, 
                    inquire about the ways in which Personal Data relating to 
                    you has been or may have been used or disclosed by SEC 
                    within the past year, wish to withdraw your consent to our 
                    use of such Personal Data, or provide feedback on our Policy, 
                    you may contact our data protection officer at [●]. We will 
                    seek to attend to your request as best as we reasonably can.
                  </li>
                  <li>SEC reserves the right to charge such fees as it made, in 
                    its sole and absolute discretion, deem appropriate for the 
                    grant of such access and to correct any Personal Data.
                  </li>
                </ol>
              </li>
              <li>
                <h1>Changes to Policy</h1>
                <ol className="sub-list">
                  <li>This Policy may be amended and updated by SEC from time to 
                    time. Any such amendment or update will be made available on 
                    the Platform and will be effective immediately upon such 
                    posting. We are under no obligation to separately inform you 
                    of any such revision, and it shall be your responsibility to 
                    review this Policy upon each access or use to ensure that 
                    you are aware of any changes made by us. Your continued 
                    access of our Platform after the revised policy has taken 
                    effect will constitute your unconditional acceptance of such 
                    revised policy. All communications, transactions and dealings 
                    with SEC will be subject to the latest version of this policy 
                    in force at the relevant time.
                  </li>
                  <li>If you do not agree to any of the changes, we are not obliged 
                    to continue providing you with any Service and you must stop 
                    using the Platform and the Services.
                  </li>
                </ol>
              </li>
              <li>
                <h1>Acknowledgments and Disclaimers</h1>
                <ol className="sub-list">
                  <li>You acknowledge that you are transmitting Personal Data to 
                    us at your own risk. Please contact us immediately if you 
                    become aware or have reason to believe there has been any 
                    unauthorised use of the Personal Data provided by you.
                  </li>
                  <li>While SEC is committed to protecting Personal Data, you 
                    agree and acknowledge that the Platform or its contents may 
                    not be free of errors, computer viruses and/or other harmful 
                    or corrupting code, program, macros and such other 
                    unauthorised software. You agree that you will not hold us 
                    liable for any disclosure of Personal Data provided by you 
                    resulting from events and circumstances beyond our control.
                  </li>
                  <li>SEC may from time to time send you and/or the Relevant 
                    Individuals email messages or short message service (SMS) to 
                    mobile phones based on Personal Data provided to us. We do 
                    not guarantee that these messages will be completely secure. 
                    Under no circumstance will SEC be liable for any damages 
                    incurred or sustained by you or the Relevant Individuals in 
                    connection with or arising from any such messages transmitted 
                    by SEC or to SEC.
                  </li>
                  <li>You agree not to hold SEC liable for any violation, breach 
                    or non-compliance with this Policy where such violation, 
                    breach or non-compliance arises from:
                    <ol className="lower-alpha">
                      <li>any unforeseeable circumstances which have occurred, 
                        resulting from the malfunction, damage or destruction of 
                        any equipment and/or machinery which is used to secure, 
                        store or process personal data or information from the 
                        Users;
                      </li>
                      <li>where the Personal Data is already available or able to 
                        be found by the public before any Personal Data of such 
                        kind has been submitted to us; and/or
                      </li>
                      <li>where after every reasonable effort and attempt has been 
                        made by us to secure and safeguard any personal data and 
                        information submitted to us, there is unauthorised access, 
                        hacking, misuse, modification, alteration, tampering, or 
                        abuse of such Personal Data caused by malicious, fraudulent 
                        or criminal acts of any kind or misconduct of a third party 
                        not being under our control or instruction.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Links to other websites</h1>
                <ul>
                  <li>Our Platform may contain links to other websites. This 
                    Privacy Policy is only applicable to this Platform. Hence, 
                    should you be linked to other websites, you should read 
                    those websites’ own privacy policies.
                  </li>
                </ul>
              </li>
              <li>
                <h1>Governing Law</h1>
                <ul>
                  <li>This Privacy Policy and your use of the Platform shall be 
                    governed by the laws of Singapore.
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
        <Footer />
      </Layout>
    </section>
  );
};

export default PrivacyPolicy;
